import pushGTMEvent, { EcommerceEvent, pushGTMEcommerceEvent } from '@helpers/analyticsHelpers/pushGTMEvent';
import { convertProductsToAnalyticsEcommerceItems } from '@helpers/analyticsHelpers/analyticsProduct';

export const trackRemoveOrAddToCart = (
  event: EcommerceEvent.ADD_TO_CART | EcommerceEvent.REMOVE_FROM_CART,
  listName: string,
  products: any[]
) => {
  pushGTMEcommerceEvent({
    event,
    ecommerce: {
      items: convertProductsToAnalyticsEcommerceItems(products, undefined, listName),
    },
  });
};

export const trackConflictListModalViewed = (action: string, reason?: string) => {
  pushGTMEvent({
    event: 'Track',
    category: 'outofstock_modal',
    action,
    label: reason,
    value: 0,
  });
};

import styled from 'styled-components';
import variables from '@styles/variables';
import { rem, rgba } from 'polished';

export const StyledPopoverMenu = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${variables.colors.white};
  box-shadow: 0 0 ${rem(28)} 0 ${rgba(variables.colors.black, 0.1)};
  z-index: ${variables.zIndex.toolbar + 1};
  border: ${rem(1)} solid ${variables.colors.mediumGray};
  border-radius: ${rem(18)};
`;

import Label from '@atoms/Label/Label';
import Text from '@atoms/Text/Text';
import variables from '@styles/variables';
import { rem } from 'polished';
import styled, { css } from 'styled-components';
import { ConflictListItemType } from './ConflictListItem';

export const StyledConflictListItem = styled.div<{ variant: ConflictListItemType; isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  min-height: ${rem(100)};
  padding: ${rem(16)} ${rem(12)};
  overflow: hidden;
  cursor: pointer;

  &:last-child {
    margin-bottom: ${rem(60)};
  }

  ${(props) =>
    (props.variant === 'conflicting' || props.variant === 'non-replaceable') &&
    css`
      border-bottom: ${rem(1)} dashed ${variables.colors.mediumGray};
      cursor: default;
      &:first-child {
        border-top: ${rem(1)} dashed ${variables.colors.mediumGray};
      }
      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
      }
    `}

  ${(props) =>
    props.variant === 'non-replaceable' &&
    css`
      cursor: initial;
    `}

  ${(props) =>
    props.variant === 'replaceable' &&
    css`
      border: ${rem(1)} solid ${variables.colors.mediumGray};
      margin-top: ${rem(20)};

      &:first-child {
        margin-top: ${rem(8)};
      }

      &:hover {
        @media (hover: hover) and (pointer: fine) {
          border-color: ${variables.colors.highlight};
        }
      }

      &:last-child {
        margin-bottom: ${rem(20)};
      }
    `}

  ${(props) =>
    props.isSelected &&
    css`
      border-color: ${variables.colors.highlight};
    `}
`;

export const StyledConflictListItemWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  &:last-child {
    border-bottom: none;
  }
`;

export const StyledConflictListItemTextWrapper = styled.div`
  margin: 0 ${rem(20)};
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  min-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const StyledConflictListItemText = styled(Text)`
  line-height: ${rem(16)};
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: ${rem(4)};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledConflictListItemTextLabel = styled(Label)`
  width: ${rem(208)};
`;

import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/useAppDispatch';
import { addToCart } from '@api/interfaces/cartApi';
import { trackProductImpressions } from '@helpers/analyticsHelpers/trackProductImpressions';
import { trackConflictListModalViewed, trackRemoveOrAddToCart } from '@helpers/analyticsHelpers/trackCartConflict';
import { selectConflictingModal, openConflictingModal, setStateConflictingModal } from '@slices/conflictingModalSlice';
import { addListToCartReason, addOrderToCartReason } from '@hooks/useCheckCartConflict';
import getPickUnit from '@helpers/getPickUnit';
import { EcommerceEvent } from '@helpers/analyticsHelpers/pushGTMEvent';
import {
  AddToCartForm,
  AxfoodCartEntryStatusViewModel,
  AxfoodProductDetailsViewModel,
  AxfoodReplacementProductViewModel,
} from '@occ/api-client';
import useCart from '@hooks/useCart';

const useCartConflict = () => {
  const dispatch = useAppDispatch();

  const conflictingModal = useAppSelector(selectConflictingModal);
  const { items } = conflictingModal;
  const { cart, refreshCart } = useCart();
  const cartConflictModalBody = useRef<HTMLDivElement>(null);
  const replaceableItems = items.filter(
    (item) =>
      !item.notAllowedB2b &&
      !item.notAllowedAnonymous &&
      !item.notAllowedExternalPickupLocation &&
      !item.notAllowedBulkyProduct &&
      item.replacementProductsForProduct.length
  );
  const nonReplaceableItems = items.filter(
    (item) =>
      item.notAllowedB2b ||
      item.notAllowedAnonymous ||
      item.notAllowedExternalPickupLocation ||
      item.notAllowedBulkyProduct ||
      !item.replacementProductsForProduct.length
  );
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [currentItem, setCurrentItem] = useState(replaceableItems.length ? replaceableItems[currentItemIndex] : null);
  const [selectedReplacementItem, setSelectedReplacementItem] = useState<AxfoodReplacementProductViewModel | null>(null);
  const [selectedReplacementItemQuantity, setSelectedReplacementItemQuantity] = useState<number | undefined>(undefined);
  const [showNonReplaceable, setShowNonReplaceable] = useState(!replaceableItems.length);

  // loading states
  const [dontReplaceLoading, setDontReplaceLoading] = useState(false);
  const [replaceLoading, setReplaceLoading] = useState(false);
  const [continueLoading, setContinueLoading] = useState(false);

  const onConflictListItemHandler = (item: AxfoodReplacementProductViewModel) => {
    setSelectedReplacementItem(item);
    setSelectedReplacementItemQuantity(item.replacementQty);
  };

  const onCloseHandler = () => {
    if (conflictingModal.rejected) {
      conflictingModal.rejected(new Error('Canceled handling conflicts'));
      dispatch(openConflictingModal({ open: false }));
    }
  };

  function getAmountAlreadyInCart(productCode: string) {
    const product = cart?.products.find((item) => item.code === productCode);
    return product?.pickQuantity || 0;
  }

  const resolveAndCloseConflictModal = () => {
    if (conflictingModal.resolved) {
      conflictingModal.resolved('');
      dispatch(openConflictingModal({ open: false }));
    }
  };

  const handleNextItem = () => {
    if (currentItemIndex + 1 < replaceableItems.length) {
      const nextItemIndex = currentItemIndex + 1;
      setCurrentItemIndex(nextItemIndex);
      setCurrentItem(replaceableItems[nextItemIndex]);
    } else if (nonReplaceableItems.length) {
      setCurrentItem(null);
      setShowNonReplaceable(true);
    } else {
      resolveAndCloseConflictModal();
    }
  };

  const toAddToCartForm = (item: AxfoodCartEntryStatusViewModel): AddToCartForm => {
    return {
      productCodePost: item.entryProduct!.code,
      qty: item.availableQuantity || 0,
      pickUnit: getPickUnit(item.entryProduct!.productBasketType?.code),
    };
  };

  const doAddToCart = async (items: AddToCartForm[], onFinally: () => void) => {
    try {
      const data = await addToCart(items, conflictingModal.storeId);
      await refreshCart(data);

      setSelectedReplacementItem(null);
      handleNextItem();
    } finally {
      cartConflictModalBody?.current?.scrollTo({
        top: 0,
        behavior: 'smooth',
      });

      if (onFinally) {
        onFinally();
      }
    }
  };

  const onQuantityChange = (qty: number, item: AxfoodProductDetailsViewModel) => {
    setSelectedReplacementItem(item);
    setSelectedReplacementItemQuantity(qty);
  };

  const replace = async () => {
    setReplaceLoading(true);

    if (selectedReplacementItemQuantity === 0) {
      // If replacement quantity is 0, assume user wants to remove conflicting item
      await removeProduct(true);
      setReplaceLoading(false);
      return;
    }

    const replaceItem: AddToCartForm = {
      productCodePost: selectedReplacementItem!.code,
      qty: (selectedReplacementItemQuantity || 0) + getAmountAlreadyInCart(selectedReplacementItem!.code),
      pickUnit: getPickUnit(selectedReplacementItem!.productBasketType?.code),
    };

    if (conflictingModal?.reason === addOrderToCartReason || conflictingModal?.reason === addListToCartReason) {
      await doAddToCart([replaceItem], () => setReplaceLoading(false));
    } else {
      const currentItemData = toAddToCartForm(currentItem!);
      const items = [replaceItem, currentItemData];

      await doAddToCart(items, () => setReplaceLoading(false));

      if (currentItem && selectedReplacementItem) {
        trackRemoveOrAddToCart(
          EcommerceEvent.ADD_TO_CART,
          `outofstock_modal | with_suggestions | ${currentItem.entryProduct?.name} | ${currentItem.entryProduct?.code}`,
          [
            {
              ...selectedReplacementItem,
              pickQuantity: selectedReplacementItemQuantity!,
            },
          ]
        );

        trackRemoveOrAddToCart(
          EcommerceEvent.REMOVE_FROM_CART,
          `outofstock_modal | with_suggestions | ${currentItem.entryProduct?.name} | ${currentItem.entryProduct?.code}`,
          [{ ...currentItem.entryProduct, pickQuantity: currentItem.pickQuantity! }]
        );
      }
    }
  };

  const removeProduct = async (disableLoading?: boolean) => {
    if (!disableLoading) {
      setDontReplaceLoading(true);
    }

    const dontReplaceItem =
      conflictingModal?.reason !== addOrderToCartReason && conflictingModal?.reason !== addListToCartReason
        ? [toAddToCartForm(currentItem!)]
        : [];
    await doAddToCart(dontReplaceItem, () => setDontReplaceLoading(false));
    // Added if to fix typescript complaint about currentItem possible null
    if (currentItem) {
      trackRemoveOrAddToCart(
        EcommerceEvent.REMOVE_FROM_CART,
        `outofstock_modal | with_suggestions | ${currentItem.entryProduct?.name} | ${currentItem.entryProduct?.code}`,
        [{ ...currentItem.entryProduct, pickQuantity: currentItem.pickQuantity! }]
      );
    }
  };

  const getAvailableQuantity = (product: AxfoodCartEntryStatusViewModel) => {
    if (
      product.notAllowedB2b ||
      product.notAllowedAnonymous ||
      product.notAllowedExternalPickupLocation ||
      product.notAllowedBulkyProduct
    ) {
      return 0;
    }
    return product.availableQuantity ? product.availableQuantity : 0;
  };

  const removeAllNonReplaceable = async () => {
    const listOfNonReplaceableItems = nonReplaceableItems.map((item): AxfoodProductDetailsViewModel => {
      return { ...item.entryProduct, pickQuantity: item.pickQuantity };
    });
    setContinueLoading(true);
    const nonReplaceableRequestData: AddToCartForm[] = nonReplaceableItems.map((item) => ({
      productCodePost: item.entryProduct.code,
      qty: getAvailableQuantity(item),
      pickUnit: getPickUnit(item.entryProduct.productBasketType?.code),
    }));
    await doAddToCart(nonReplaceableRequestData, () => {
      setContinueLoading(false);
      resolveAndCloseConflictModal();
      trackRemoveOrAddToCart(
        EcommerceEvent.REMOVE_FROM_CART,
        'outofstock_modal | without_suggestions',
        listOfNonReplaceableItems
      );
    });
  };

  useEffect(() => {
    const action = showNonReplaceable ? 'shown_without_replacement_suggestions' : 'shown_with_replacement_suggestions';
    trackConflictListModalViewed(action, conflictingModal?.reason);
    if (showNonReplaceable) {
      const entryProducts = nonReplaceableItems.map((item) => item.entryProduct);
      trackProductImpressions(entryProducts, 'outofstock_modal | without_suggestions', 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNonReplaceable]);

  useEffect(() => {
    if (currentItem && !showNonReplaceable) {
      const { entryProduct, replacementProductsForProduct } = replaceableItems[currentItemIndex];
      const list = `outofstock_modal | with_suggestions | ${entryProduct.name} | ${entryProduct.code}`;
      trackProductImpressions(replacementProductsForProduct, list, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItem]);

  useEffect(() => {
    return () => {
      dispatch(
        setStateConflictingModal({
          open: false,
          items: [],
          resolved: null,
          rejected: null,
          reason: '',
        })
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    onCloseHandler,
    onQuantityChange,
    removeProduct,
    replace,
    resolveAndCloseConflictModal,
    removeAllNonReplaceable,
    onConflictListItemHandler,
    showNonReplaceable,
    nonReplaceableItems,
    currentItemIndex,
    replaceableItems,
    currentItem,
    selectedReplacementItem,
    dontReplaceLoading,
    cartConflictModalBody,
    replaceLoading,
    continueLoading,
    conflictingModal,
  };
};

export default useCartConflict;

import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';

export const trackCartPreviewEmptyState = () => {
  pushGTMEvent({
    event: 'Track',
    category: 'minicart',
    action: 'editor_suggestion',
    label: '',
    value: 0,
  });
};

export const trackCartPreviewEmptyStateLinkClick = (label: string) => {
  pushGTMEvent({
    event: 'Track',
    category: 'minicart',
    action: 'continued_to_editor_suggestion',
    label,
    value: 0,
  });
};

import { ReactNode } from 'react';
import { StyledButton, StyledPopoverMenuItem, StyledText } from './PopoverMenuItem.styles';

interface PopoverMenuItemProps {
  onClick: () => void;
  selected?: boolean;
  children: ReactNode;
  disabled?: boolean;
}

const PopoverMenuItem = ({ onClick, selected, children, disabled }: PopoverMenuItemProps) => {
  return (
    <StyledPopoverMenuItem>
      <StyledButton theme="transparent" onClick={() => !disabled && onClick()}>
        <StyledText type="body" disabled={disabled}>
          {selected && <strong>{children}</strong>}
          {!selected && children}
        </StyledText>
      </StyledButton>
    </StyledPopoverMenuItem>
  );
};

export default PopoverMenuItem;

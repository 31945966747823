import { MouseEvent, RefObject, useEffect, useRef, useState } from 'react';
import { useAppSelector } from '@hooks/useAppDispatch';
import useResizeObserver from '@react-hook/resize-observer';
import Text from '@atoms/Text/Text';
import Button from '@atoms/Button/Button';
import Link from '@atoms/Link/Link';
import ProductListItem from '@molecules/ProductListItem/ProductListItem';
import EmptyCartModal from '@organisms/EmptyCartModal/EmptyCartModal';
import { makeProductEntries } from '@helpers/wishlistProductEntries';
import useCustomer from '@hooks/useCustomer';
import paths, { LoginQueryParams } from '@constants/paths';
import { selectMiniCartPreviewIsOpen } from '@slices/miniCartSlice';
import webToAppApi from '@api/web-to-app';
import Config from '@config';
import trackShoppingList from '@helpers/analyticsHelpers/trackShoppingList';
import {
  StyledAddToListDropdown,
  StyledCartPreview,
  StyledCartPreviewBodyWrapper,
  StyledCartPreviewContainer,
  StyledCartPreviewFooter,
  StyledCartPreviewFooterRow,
  StyledCartPreviewGoToCartButton,
  StyledCartPreviewGoToCartLink,
  StyledCartPreviewHeader,
  StyledCartPreviewHeaderHeading,
  StyledCartPreviewHeaderInnerWrapper,
  StyledCartPreviewNoItemsHeading,
  StyledSpinner,
} from '@molecules/MiniCart/Preview/CartPreview.styles';
import Icon from '@atoms/Icon/Icon';
import PopoverMenu from '@molecules/PopoverMenu/PopoverMenu';
import PopoverMenuItem from '@molecules/PopoverMenu/PopoverMenuItem/PopoverMenuItem';
import {
  trackCartPreviewEmptyState,
  trackCartPreviewEmptyStateLinkClick,
} from '@helpers/analyticsHelpers/trackCartPreview';
import EllipsisVertical from '@icons/menu-secondary.svg';
import useCart from '@hooks/useCart';
import DynamicNamespaces from 'next-translate/DynamicNamespaces';
import useTranslation from 'next-translate/useTranslation';
import Trans from 'next-translate/Trans';

type Props = {
  headerRef: RefObject<HTMLDivElement>;
};

const CartPreview = ({ headerRef }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [shadowFactor, setShadowFactor] = useState(0);
  const [isEmptyCartModalVisible, setEmptyCartModalVisibility] = useState(false);
  const { cart, isCartEmpty, hasFetchedCart } = useCart();
  const { t } = useTranslation();
  const isCartPreviewOpen = useAppSelector(selectMiniCartPreviewIsOpen);
  const productEntriesForWishlist = makeProductEntries(cart?.products);
  const { customer } = useCustomer();

  const cartPreviewRef = useRef<HTMLDivElement>(null);
  const outerRef = useRef<HTMLElement>(null);

  const queryParam = { from: LoginQueryParams.CART };

  const onGoToCart = (e: MouseEvent) => {
    if (isCartEmpty) e.preventDefault();
  };

  const scrollHandler = () => {
    const offsetHeight = (headerRef.current?.offsetHeight || 60) + Config.HEIGHTS.STICKY_TOOLBAR;
    if (offsetHeight && cartPreviewRef.current) {
      if (window.pageYOffset >= offsetHeight - Config.HEIGHTS.STICKY_TOOLBAR) {
        cartPreviewRef.current.style.height = `calc(100vh - ${Config.HEIGHTS.STICKY_TOOLBAR}px)`;
      } else {
        cartPreviewRef.current.style.height = `calc(100vh - ${offsetHeight - window.pageYOffset}px)`;
      }
    }
  };

  useResizeObserver<HTMLDivElement>(headerRef, () => {
    scrollHandler();
  });

  const productListScrollHandler = (el: HTMLElement) => {
    // Calculate number of pixels left to be scrolled until the bottom of the element
    const px = el.scrollHeight - el.scrollTop - el.clientHeight;
    setShadowFactor(Math.min(50, px) / 50);
  };

  useResizeObserver<HTMLElement>(outerRef, () => {
    outerRef.current && productListScrollHandler(outerRef.current);
  });

  useEffect(() => {
    document.addEventListener('scroll', scrollHandler);
    return () => {
      document.removeEventListener('scroll', scrollHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (outerRef.current) {
      productListScrollHandler(outerRef.current);
    }
  }, [cart?.products]);

  useEffect(() => {
    if (outerRef.current) {
      const current = outerRef.current;
      current.addEventListener('scroll', () => productListScrollHandler(current));
      return () => {
        current.removeEventListener('scroll', () => productListScrollHandler(current));
      };
    }
  }, [outerRef]);

  const openEmptyCartModal = () => {
    setEmptyCartModalVisibility(true);
  };

  useEffect(() => {
    // Focus on first button in the minicart when it opens, for A11y
    if (isCartPreviewOpen) {
      const firstButton = cartPreviewRef?.current?.querySelector('button');
      firstButton?.focus();
    }
  }, [isCartPreviewOpen]);

  useEffect(() => {
    if (isCartPreviewOpen && hasFetchedCart && !cart?.products?.length) {
      trackCartPreviewEmptyState();
    }
  }, [cart, isCartPreviewOpen, hasFetchedCart]);

  return (
    <>
      <EmptyCartModal
        visible={isEmptyCartModalVisible}
        setVisibility={setEmptyCartModalVisibility}
        tracking={{ actionFieldList: 'minicart', category: 'minicart', action: 'removed_all_products_from_cart' }}
      />
      <StyledCartPreview
        data-testid="cart-preview"
        ref={cartPreviewRef}
        isOpen={isCartPreviewOpen}
        role="complementary"
        aria-label={t('cartpreview:minicartLabel')}
      >
        <DynamicNamespaces namespaces={['cartpreview', 'cartpage']}>
          <StyledCartPreviewHeader>
            <StyledCartPreviewHeaderInnerWrapper>
              <StyledCartPreviewHeaderHeading variant="h2" size="small">
                <Trans i18nKey="cartpreview:cart->heading" values={{ count: cart?.totalUnitCount || 0 }} />
              </StyledCartPreviewHeaderHeading>
              <StyledAddToListDropdown
                productEntries={productEntriesForWishlist}
                align="top"
                forceJustify="right"
                btnIconSize={20}
                disabled={productEntriesForWishlist?.length === 0}
                queryParamsIfNotLoggedIn={queryParam}
                tracking={{ parent: 'minicart' }}
                addToListCallback={({ option, productEntries }) => {
                  webToAppApi.eventShoppingListAddedToCart(productEntries.length, option.value);
                  trackShoppingList({ action: 'current', parent: 'minicart' });
                }}
                createNewListCallback={({ inputValue }) => {
                  webToAppApi.eventShoppingListCreated(inputValue);
                  trackShoppingList({ action: 'new', parent: 'minicart' });
                }}
              />

              <Button
                theme="transparent"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsMenuOpen(true);
                }}
                data-testid="cartpreview-menu"
                aria-label={t('cartpreview:openMenuLabel')}
              >
                <Icon svg={EllipsisVertical} size={20} />
              </Button>

              <PopoverMenu open={isMenuOpen} handleClose={() => setIsMenuOpen(false)}>
                <PopoverMenuItem onClick={openEmptyCartModal} disabled={!cart?.products?.length}>
                  <Trans i18nKey="cartpreview:cart->clear" />
                </PopoverMenuItem>
              </PopoverMenu>
            </StyledCartPreviewHeaderInnerWrapper>
          </StyledCartPreviewHeader>
          <StyledCartPreviewBodyWrapper ref={outerRef}>
            {hasFetchedCart ? (
              <>
                {cart?.products?.length ? (
                  <div>
                    {cart.products.map((product) => (
                      <ProductListItem product={product} key={`cart-row-${product.name}-${product.code}`} />
                    ))}
                  </div>
                ) : (
                  <StyledCartPreviewContainer>
                    <StyledCartPreviewNoItemsHeading type="subhead" size="medium">
                      <Trans i18nKey="cartpreview:cart->empty->title" />
                    </StyledCartPreviewNoItemsHeading>

                    {customer?.isAnonymous ? (
                      <Text type="body" size="small">
                        <Trans
                          i18nKey="cartpreview:cart->empty->anonymousText"
                          components={[
                            <Link
                              href={paths.USER_LOGIN}
                              shallow
                              key={0}
                              onClick={() =>
                                trackCartPreviewEmptyStateLinkClick(t('cartpreview:editorSuggestions->logIn'))
                              }
                            >
                              {}
                            </Link>,
                            <span key={1} />,
                          ]}
                        />
                      </Text>
                    ) : (
                      <Text type="body" size="small">
                        {customer?.isB2BCustomer ? (
                          <Trans
                            i18nKey="cartpreview:cart->empty->loggedInTextB2B"
                            components={[
                              <Link
                                href={paths.MY_LISTS}
                                shallow
                                key={0}
                                onClick={() =>
                                  trackCartPreviewEmptyStateLinkClick(t('cartpreview:editorSuggestions->myLists'))
                                }
                              >
                                {}
                              </Link>,
                              <Link
                                href={paths.ACCOUNT_ORDERS}
                                shallow
                                key={1}
                                onClick={() =>
                                  trackCartPreviewEmptyStateLinkClick(t('cartpreview:editorSuggestions->myOrders'))
                                }
                              >
                                {}
                              </Link>,
                            ]}
                          />
                        ) : (
                          <Trans
                            i18nKey="cartpreview:cart->empty->loggedInText"
                            components={[
                              <Link
                                href={paths.MY_COMMON_ITEMS}
                                shallow
                                key={0}
                                onClick={() =>
                                  trackCartPreviewEmptyStateLinkClick(t('cartpreview:editorSuggestions->myCommonItems'))
                                }
                              >
                                {}
                              </Link>,
                              <Link
                                href={paths.MY_LISTS}
                                shallow
                                key={1}
                                onClick={() =>
                                  trackCartPreviewEmptyStateLinkClick(t('cartpreview:editorSuggestions->myLists'))
                                }
                              >
                                {}
                              </Link>,
                              <Link
                                href={paths.ACCOUNT_ORDERS}
                                shallow
                                key={2}
                                onClick={() =>
                                  trackCartPreviewEmptyStateLinkClick(t('cartpreview:editorSuggestions->myOrders'))
                                }
                              >
                                {}
                              </Link>,
                            ]}
                          />
                        )}
                      </Text>
                    )}
                  </StyledCartPreviewContainer>
                )}
              </>
            ) : (
              <StyledCartPreviewContainer>
                <div>
                  <Text type="subhead" size="small">
                    <Trans i18nKey="cartpage:loading->title" />
                  </Text>
                  <StyledSpinner color="black" size={32} />
                </div>
                <Text type="body" size="small">
                  <Trans i18nKey="cartpage:loading->body" />
                </Text>
              </StyledCartPreviewContainer>
            )}
          </StyledCartPreviewBodyWrapper>
          <StyledCartPreviewFooter shadowFactor={shadowFactor}>
            <StyledCartPreviewFooterRow>
              <Text type="body" size="small">
                <Trans i18nKey="cartpreview:cart->totalPrice" />
              </Text>
              <Text type="price">{cart?.subTotalWithDiscounts || '0,00 kr'}</Text>
            </StyledCartPreviewFooterRow>
            <StyledCartPreviewFooterRow>
              <Text type="body" size="small">
                <Trans i18nKey="cartpreview:cart->youSave" />
              </Text>

              <Text type="price" size="small" color="red">
                {cart?.totalPromotionDiscount || '0,00 kr'}
              </Text>
            </StyledCartPreviewFooterRow>

            <StyledCartPreviewGoToCartLink onClick={onGoToCart} href={paths.CART} variant="secondary" prefetch={false}>
              <StyledCartPreviewGoToCartButton disabled={!cart?.products?.length}>
                <Trans i18nKey="cartpreview:cart->goToCheckout" />
              </StyledCartPreviewGoToCartButton>
            </StyledCartPreviewGoToCartLink>
          </StyledCartPreviewFooter>
        </DynamicNamespaces>
      </StyledCartPreview>
    </>
  );
};

export default CartPreview;

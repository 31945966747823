import styled, { css } from 'styled-components';
import { rem } from 'polished';
import variables from '@styles/variables';
import Button from '@atoms/Button/Button';
import Text from '@atoms/Text/Text';
import Config from '@config';

export const StyledPopoverMenuItem = styled.div`
  white-space: nowrap;
  min-width: ${rem(160)};

  ${Config.MEDIA.FROM_DESKTOP} {
    min-width: ${rem(194)};
  }

  &:not(:last-child) {
    border-bottom: ${rem(1)} solid;
    border-color: ${variables.colors.mediumGray};
  }
`;

export const StyledButton = styled(Button)`
  padding: ${rem(14)} ${rem(24)};
  width: 100%;
  text-align: left;
  height: ${rem(52)};
`;

export const StyledText = styled(Text)<{ disabled?: boolean }>`
  width: 100%;

  ${(props) =>
    props.disabled &&
    css`
      color: ${variables.colors.darkGray};
      cursor: default;
    `}
`;

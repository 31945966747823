import { ReactNode } from 'react';
import OutsideClickWatcher from '@utility/OutsideClickWatcher/OutsideClickWatcher';
import { StyledPopoverMenu } from './PopoverMenu.styles';

interface PopoverMenuProps {
  open: boolean;
  children: ReactNode;
  handleClose: () => void;
  'data-testid'?: string;
  className?: string;
}

const PopoverMenu = ({ open, handleClose, children, className, ...props }: PopoverMenuProps) => {
  return (
    <>
      {open && (
        <OutsideClickWatcher clickHandler={handleClose}>
          <StyledPopoverMenu data-testid={props['data-testid']} className={className} onClick={handleClose}>
            {children}
          </StyledPopoverMenu>
        </OutsideClickWatcher>
      )}
    </>
  );
};

export default PopoverMenu;

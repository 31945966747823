import Button from '@atoms/Button/Button';
import Heading from '@atoms/Heading/Heading';
import Text from '@atoms/Text/Text';
import { rem } from 'polished';
import styled from 'styled-components';

export const StyledMergeCartHeading = styled(Heading)`
  margin-bottom: ${rem(15)} !important;
`;

export const StyledMergeCartText = styled(Text)`
  text-align: left;
  margin-bottom: ${rem(25)};
`;

export const StyledMergeCartButton = styled(Button)`
  width: 100%;
  margin-bottom: ${rem(15)} !important;

  &:last-child {
    margin-bottom: 0 !important;
  }
`;

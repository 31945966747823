import CART from '@helpers/cartKeys';
import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';

type MergeTrackOptionActions = 'anonymous_cart' | 'loggedin_cart' | 'merge_carts';

const track = (option: MergeTrackOptionActions) => {
  pushGTMEvent({
    event: 'Track',
    category: 'cart_merge_modal',
    action: `continue_with_${option}`,
    label: '',
    value: 0,
  });
};

const trackMergeCartModal = (action: string) => {
  switch (action) {
    case CART.KEEP_SESSION:
      track('anonymous_cart');
      break;
    case CART.KEEP_PERSISTENT:
      track('loggedin_cart');
      break;
    case CART.MERGE:
      track('merge_carts');
      break;
    default:
      break;
  }
};

export const trackMergeCartModalClose = () => {
  pushGTMEvent({
    event: 'Track',
    category: 'cart_merge_modal',
    action: 'merge_modal_closed',
    label: '',
    value: 0,
  });
};

export default trackMergeCartModal;

import { useEffect, useState } from 'react';
import { useAppSelector } from '@hooks/useAppDispatch';
import webToAppApi from '@api/web-to-app';
import { selectCartIsUpdating } from '@slices/cartUpdatingSlice';

const useSendCartCountToNativeApp = (unitCount: number) => {
  const [prevCount, setPrevCount] = useState(unitCount);
  const isUpdating = useAppSelector(selectCartIsUpdating);
  const [displayCartCountSent, setDisplayCartCountSent] = useState(false);

  // TODO: always send on cart.totalUnitCount update when native app no longer updates cart on this event
  useEffect(() => {
    if (unitCount !== prevCount && !isUpdating && !displayCartCountSent) {
      setDisplayCartCountSent(true);
      webToAppApi.actionDisplayCartCount(unitCount);
    }
  }, [unitCount]);

  /* Send when cart updates: totalUnitCount, address etc. */
  useEffect(() => {
    if (prevCount !== unitCount && !isUpdating) {
      setPrevCount(unitCount);
      webToAppApi.eventCartUpdated(unitCount);
    }
  }, [isUpdating, unitCount]);
};

export default useSendCartCountToNativeApp;

import { RefObject } from 'react';
import CartConflictModal from '@components/organisms/CartConflictModal/CartConflictModal';
import MergeCartModal from '@components/organisms/MergeCartModal/MergeCartModal';
import CartPreview from '@molecules/MiniCart/Preview/CartPreview';
import useSendCartCountToNativeApp from '@hooks/useSendCartCountToNativeApp';
import { selectConflictingModal } from '@slices/conflictingModalSlice';
import useUserAgent from '@hooks/useUserAgent';
import usePersistedCart from '@hooks/usePersistedCart';
import useCart from '@hooks/useCart';
import { useAppSelector } from '@hooks/useAppDispatch';
import useResponsive from '@hooks/useResponsive';

interface Props {
  headerRef: RefObject<HTMLDivElement>;
}

const CartModals = ({ headerRef }: Props) => {
  const { isWebOrNativeAndEcommerce } = useUserAgent();
  const { persistedCart } = usePersistedCart();
  const { cart, isCartEmpty } = useCart();
  const conflictingModal = useAppSelector(selectConflictingModal);
  const { fromDesktop } = useResponsive();

  useSendCartCountToNativeApp(cart?.totalUnitCount || 0);

  return (
    <>
      {conflictingModal.open && <CartConflictModal />}
      {(!isCartEmpty || cart?.slot !== null) && persistedCart && persistedCart.totalPersistedCartProducts > 0 && (
        <MergeCartModal />
      )}
      {isWebOrNativeAndEcommerce && fromDesktop && <CartPreview headerRef={headerRef} />}
    </>
  );
};

export default CartModals;
